module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"INNVIS","short_name":"INNVIS","start_url":"/","background_color":"#202020","theme_color":"#182064","display":"standalone","cache_busting_mode":"none","icon":"src/assets/images/innvis-favicon.png","icons":[{"src":"/src/assets/images/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/src/assets/images/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"description":"INNVIS - Your Vision Inspires, Our Innovation Transforms! Unlock the power of innovation as your vision inspires us to deliver top-quality results. Welcome to a new realm of possibilities.","lang":"en","dir":"ltr","orientation":"portrait","prefer_related_applications":false,"related_applications":[],"scope":"/","categories":["Business","Technology","Automation","Marketing","Studio"],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHKXK6D","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
